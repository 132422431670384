table.headline-table {

  table-layout: fixed;

  td {
    white-space: nowrap;
    font-size: 12px;
  }

  th.dark-col, td.dark-col {

    background-color: $gray-800;
    color: $gray-200;
  }

  th.lighter-col, td.lighter-col {

    //background-color: $gray-300;
    //color: $gray-800;
  }


}

.capacity-table
{
  padding: 0.3rem !important;
  padding-bottom: 0.95rem !important;
  padding-top: 0.95rem !important;
}

.headline-table {
  &__data-col {
    width: 9%;
  }

  &__day-col {
    width: 19%;
  }

  &__nbr-col {
    width: 5%;
  }
}

.dark-bottom-border {
  border-bottom: 2px solid black;

  td {
    border-bottom: 2px solid black;

  }
}

.arrow-class-cell {

  .direction-cont {
    position: relative;

    .change-direction {
      position: absolute;
      top: -25px;
      right: -20px;
    }
  }

  .change-direction {
    font-size: 18px;
  }

  .dripicons-arrow-thin-up {
    color: green;
  }

  .dripicons-arrow-thin-down {
    color: red;
  }
}