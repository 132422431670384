.report-options {
  display: flex;
}

.grouping-field {
  width: 291px;
}

#grouping-selection, #yoy-selection, #brand-selection {
  display: flex;

  button:focus {
    background-color: #06a6fb !important;
    color: #ffffff !important;

  }

  button {
    margin-right: 10px !important;
  }
}
.campaign-name-cell {
  position: relative;

}

.sub-campaign-name-cell {
  position: relative;


}

.report-compare {
  display: flex;

  .compare-direction {
    font-size: 14px;
    margin-left: 5px;
  }

  .dripicons-arrow-thin-up {
    color: green;
  }

  .dripicons-arrow-thin-down {
    color: red;
  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }
}

.report-table {
  overflow-x: scroll;
}