.calendar-row {
  margin-top: 50px;
}

.calendar {
  width: 100%;
  margin-bottom: 40px;

  .calendar-head {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;

    .calendar-current {
      font-size: 28px;
      padding-right: 10px;
      padding-left: 10px;
      width: 240px;
      text-align: center;
    }

    .dripicons-chevron-left, .dripicons-chevron-right {
      font-size: 26px;
      cursor: pointer;
    }
  }

  .calendar-day-labels {
    display: flex;
    justify-content: space-between;
  }

  .day-label {
    width: 100px;
    text-align: center;
    font-size: 20px;
    color: black;
  }

  .calendar-months {
    display: flex;
    flex-direction: column;
  }

  .calendar-week {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .calendar-day {
    display: block;
    width: 100px;
    text-align: center;
    font-size: 18px;
    position: relative;
  }

  .day-nbr {
    position: relative;
    z-index: 2;
  }

  .highlight-day {
    color: #ffffff;
  }

  .day-highlighter {
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    top: -30%;
    left: 25%;
    z-index: 1;
  }

  .disabled {
    color: #bfbfbf;
  }
}

.event-list-title {
  padding-top: 50px;
}

#events {
  display: flex;
  flex-direction: column;

  .event-list-item {
    background-color: #b013ff;
    width: 100%;
    color: #ffffff;
    padding: 10px;
    border-radius: 10px;

    margin-bottom: 10px;

  }

  .event-list-item-date-range {
    font-weight: bold;
  }
}