// common across the application
body.app {

  select.form-control, input.form-control {

    //background: $gray-100;
    background: #e3eaef;
    //background: #f8faff;
    color: #313a46;
    //font-weight: bold;

  }

  div.top-row {

    margin-top: 20px;
  }

  button.table-update-button {

    margin-right: 16px;
  }

  h4.top-buffer {

    margin-top: 2.5em;
  }

  button.label-offset, a.label-offset {
    margin-top: 28px;
  }

  .ji-red-bg {

    background-color: #e30040;
  }

  .ji-amber-bg {

    background-color: #ffbb17;
  }


  .ji-green-bg {

    background-color: #60cd3e;
  }

  span.rag-capsule {

    display: block;
    width: 30px;
    height: 1.2em;
    border-radius: 200px;
  }

  div.slimscroll-menu {
    span.opportunity-badge {

      display: inline-block !important;
      padding-left: 0.3em !important;
      position: relative;
      left: -26px;
      top: -14px;
    }
  }
}

.hide {
  display: none;
}

form[name="reporting_periods"] {

  input:disabled {

    color: #98a6ad !important;
  }
}


/* narrow view */
@media only screen and (max-width: 1280px) {
  body.app {

    button.btn-opportunities {

      margin-left: 30px;

      span.extra {


        display: none;
      }
    }
  }
}

#map-key {

  position: absolute;
  margin-left: 2em;
  margin-top: 4em;
  font-family: $headings-font-family;
  font-size: 18px;
  color: $body-color;
  line-height: 1.8em;
}


#region-details {

  //display:none;
  position: absolute;
  right: 0;
  margin-right: 4em;
  margin-top: 4em;
  width: 26em;

}

#map-key a {

  color: $body-color;
}

#map-key a:hover {

  color: #06a6fb;
}

a.rag-R span {
  color: #e60061;
}

a.rag-A span {
  color: #ffc950;
}

a.rag-G span {
  color: #85de67;
}

.page-title, h4 {

  color: #534d5f;
}

ul.side-nav-second-level a.hidden-at-load {

  display: none;
}

.bottom-space-20 {
  margin-bottom: 20px;
}

//
//svg#mapimg:hover path{
//
//  fill: #000000;
//}

#events-history {
  margin-top: 25px;
}

#events-history-table {
  .dripicon {
    font-size: 26px;
  }

  .off {
    font-size: 20px;
  }

  .dripicons-arrow-thin-up {
    color: green;
  }

  .dripicons-arrow-thin-down {
    color: red;
  }

  .dripicons-chevron-down, .dripicons-chevron-up {
    cursor: pointer;
  }


  .stores {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }
}

.lookupSelect {
  width: 100%;
  background: #ffffff;
  border: 1px solid #dee2e6;
  padding: .45rem .9rem;
  border-radius: 0 0 0.25rem 0.25rem;
  border-top: 0;

  div {
    padding: .45rem .9rem;
  }
}

.read-only-row {
  color: #b2b9be;
}

.event-type-search {
  position: relative;

  .dripicons-search {
    position: absolute;
    top: 2px;
    right: 20px;
    font-size: 20px;
    cursor: pointer;
  }
}

.store-row {
  display: flex;
  justify-content: space-between;
  padding: 4px;
}

.no-margin-top {
  margin-top: 0;
}