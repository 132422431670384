// 
// topbar.scss
//

.navbar-custom {
  padding: 0 30px;
  background-color: $bg-topbar;
  box-shadow: $shadow;
  margin: -5px -20px 0;
  min-height: 70px;

  .topbar-left {
    padding: 16px 0;
    overflow: hidden;

    h3 {
      margin-top: 6px;
      margin-left: 32px;
      margin-bottom: 0px;
    }

    div.mid {

      button.btn-opportunities {

        margin-top: -2px;
        font-weight: bold;
        background: linear-gradient(135deg, #e778f0 0, #e65073 60%);

        .badge-light {

          line-height: 1;

          color: #e778f0;
          font-size: 12px;
          background: white;
        }
      }

    }
  }

  .topbar-account-section {
    float: right;
    margin-right: 20px;

    div.label {
      margin-top: 25px;
    }

    div.control {
      margin-top: 15px;
    }
  }

  .topbar-right-menu {
    li {
      float: left;
    }

    .nav-link {
      padding: 0;
      color: $gray-600;
      min-width: 32px;
      display: block;
      text-align: center;
      margin: 0 8px;
    }
  }
}

/* Search */


/* Notification */
.notification-list {
  margin-left: 0;

  .dropdown-menu.dropdown-menu-right {
    transform: none !important;
    top: 100% !important;
    right: 0 !important;
    left: auto !important;
  }

  .noti-title {
    background-color: $white;
    padding: 15px 20px;
  }

  .noti-icon {
    font-size: 22px;
    vertical-align: middle;
    line-height: 70px;
  }

  .noti-icon-badge {
    display: inline-block;
    position: absolute;
    top: 16px;
    right: 5px;
    display: inline-block;
    position: absolute;
    top: 22px;
    right: 15px;
    border-radius: 50%;
    height: 7px;
    width: 7px;
    background-color: $danger;
  }

  .notify-item {
    padding: 10px 20px;

    .notify-icon {
      float: left;
      height: 36px;
      width: 36px;
      line-height: 36px;
      text-align: center;
      margin-right: 10px;
      border-radius: 50%;
      color: $white;
    }

    .notify-details {
      margin-bottom: 0;
      overflow: hidden;
      margin-left: 45px;
      text-overflow: ellipsis;
      white-space: nowrap;

      b {
        font-weight: 500;
      }

      small {
        display: block;
      }

      span {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 13px;
      }
    }

    .user-msg {
      margin-left: 45px;
      white-space: normal;
      line-height: 16px;
    }
  }

  .profile-dropdown {
    .notify-item {
      padding: 7px 20px;
    }
  }
}

.profile-dropdown {
  width: 170px;

  i {
    vertical-align: middle;
    margin-right: 5px;
  }

  span {
    vertical-align: middle;
  }
}

.nav-user {
  padding: 17px 20px 17px 57px !important;
  text-align: left !important;
  position: relative;
  background-color: lighten($gray-100, 2.5%);
  border: 1px solid $gray-100;
  border-width: 0 1px;
  min-height: 70px;

  .account-user-avatar {
    position: absolute;
    top: 19px;
    left: 15px;

    img {
      height: 32px;
      width: 32px;
    }
  }

  .account-position {
    display: block;
    font-size: 12px;
    margin-top: -3px;
  }

  .account-user-name {
    margin-top: 4px;
    display: block;
    font-weight: $font-weight-bold;
    font-size: 16px;
  }
}

.button-menu-mobile {
  border: none;
  color: $dark;
  height: 70px;
  line-height: 70px;
  width: 60px;
  background-color: transparent;
  font-size: 24px;
  cursor: pointer;
  float: left;

  &.disable-btn {
    display: none;
  }
}

[data-keep-enlarged="true"] {
  .navbar-custom {
    padding-left: 0;
  }

  .button-menu-mobile {
    &.disable-btn {
      display: inline-block;
    }
  }
}

@media (max-width: 1024px) {
  .navbar-custom {
    left: 70px;
    right: 0;
  }
  .app-search {
    display: none;
  }
}

@include media-breakpoint-down(sm) {

  .navbar-custom {
    left: 0;
    padding: 0 10px;
    margin: -5px -10px 0;
  }
  .button-menu-mobile {
    &.disable-btn {
      display: inline-block;
    }
  }
  .nav-user {
    padding: 17px 5px 17px 57px !important;

    .account-position,
    .account-user-name {
      display: none;
    }
  }
}

.nav-top-row {
  .nav-user {
    width: 278px;
    min-height: 60px;

    .account-user-avatar {
      right: 40px !important;
      left: initial;
    }
  }

  .profile-dropdown {
    width: 278px;
  }

}

.opportunities-top-link {
  .nav-link {
    position: relative;
  }

  .opportunities-count {
    position: absolute;
    right: 40px;
    top: 19px;
    background: #7a8191;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    display: block;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    font-weight: bold;

  }

  a {
    margin-right: 0 !important;
  }
}