html.login {
  /* Full height */
  height: 100%;

}

// login page
body.login {

  background-color: black;
  background-image: url("../images/login-bg.jpg");
  height: 100%;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;

  div.container {

    p {

      color: #ffffffee;
    }

    width: 25em;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      display:block;
      margin-left:auto;
      margin-right:auto;
      height: 5em;
      margin-bottom: 20px;
    }

    input.form-control {
      border-color: white;
      color: white;
      //background-color: #fff0;
      background-color: transparent;
      height: 45px;
      font-size: 15px;
    }

    input::placeholder {

      color: #ddddddaa;
    }

    input.form-control:-webkit-autofill,
    input.form-control:-webkit-autofill:hover,
    input.form-control:-webkit-autofill:focus,
    input.form-control:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      color: white !important;
      -webkit-text-fill-color: white;
    }

    .form-check {
      margin-top: 10px;
    }

    a {
      color: white;
    }
  }
}