div.db-filters {

  margin-top: 20px;

  div.row {

    margin-top: 10px;
  }
}

div.time-series-container g.x-axis-group g.tick text {

  -webkit-transform: rotate(-45deg) translate(-45px, 0px);
  -moz-transform: rotate(-45deg) translate(-45px, 0px);
  -ms-transform: rotate(-45deg) translate(-45px, 0px);
  -o-transform: rotate(-45deg) translate(-45px, 0px);
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}

.stats-overview {
  padding-bottom: 20px;
  padding-top: 40px;
}


.stat {

  border: 1px solid #e3eaef;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
  padding: 5px 10px 20px;
  align-items: center;
  height: 140px;

  &__row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__label {
    font-size: 18px;
  }

  &__value {
    font-size: 26px;
  }

  &__arrow {
    font-size: 30px;
  }

  .dripicons-arrow-thin-up {
    color: green;
  }

  .dripicons-arrow-thin-down {
    color: red;
  }


  .red {
    color: red !important;
  }

  .green {
    color: green !important;
  }

  &__targetRow {
    width: 100%;
  }

  &__targetHead {
    display: block;
    width: 100%;
    font-weight: bold;
  }

  &__percentage {
    font-size: 24px;
    padding-left: 5px;
  }

  &__overview {
    width: 40%;
    display: flex;
    align-items: baseline;
  }

}


@media (max-width: 1280px) {
  .stat {
    flex-wrap: wrap;
    padding-bottom: 5px;
    height: 115px;

    &__label {
      font-size: 18px;

    }

    &__overview {
      width: 35%;
    }

    &__value {
      font-size: 24px;
      width: 100%;
    }

    &__percentage {
      font-size: 20px;
      padding-left: 5px;
      display: block;
      width: 82px;
    }

    &__arrow {
      font-size: 24px;
    }

    &__arrow {

    }
  }
}

@media (max-width: 995px) {
  .stat {
    flex-wrap: wrap;

    &__label {
      font-size: 14px;
    }

    &__value {
      width: 75%;
      font-size: 16px;
    }

    &__arrow {
      font-size: 14px;
    }

    &__percentage {
      font-size: 12px;
      padding-left: 5px;
      display: block;
      width: 82px;
    }


    &__arrow {

    }
  }
}

@media (max-width: 768px) {
  .stat {
    &__value {

    }
  }
}

.stat.target {
  .stat__targetHead {
  }

  .stat__targetDetail {
    font-size: 20px;
  }
}


.expand-cross {
  font-size: 24px;
  display: inline-block;
  cursor: pointer;
  transform: rotate(0deg);
  -webkit-transition: transform 0.25s;
  -moz-transition: transform 0.25s;
  -ms-transition: transform 0.25s;
  -o-transition: transform 0.25s;
  transition: transform 0.25s;
  position: relative;
  top: -6px;
}

.expand-cross.mdi-minus-circle {
  transform: rotate(180deg);
}

.level_1_Row {
  .expand-cross {
    margin-left: 20px;
  }

  td:first-child {
    padding-left: 20px;
  }
}

.level_2_Row {
  .expand-cross {
    margin-left: 40px;
  }

  td:first-child {
    padding-left: 40px;
  }
}

.level_3_Row {
  .expand-cross {
    margin-left: 40px;
  }

  td:first-child {
    padding-left: 60px;
  }
}

.collapsing {
  -webkit-transition: none;
  transition: none;
  display: none;
}

.weeks-selector {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;

  button {
    font-size: 12px;

  }
}

.days-selector {
  display: flex;
  padding-top: 20px;
  justify-content: space-between;
}

.period-selector {
  height: 70px;
}

.period-selection {
  display: flex;
}

.period-selector:nth-child(2) {
  margin-right: 10px;
  margin-left: 10px;
  flex-grow: 2;
}

.year-select {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;

  .current-year {
    margin-left: 10px;
    margin-right: 10px;
  }

  .prev-year, .next-year {
    position: relative;
  }

  .dripicons-chevron-left:nth-child(2) {
    position: absolute;
    left: 9px;
    top: 8px;
  }

  .dripicons-chevron-right:nth-child(2) {
    position: absolute;
    right: 9px;
    top: 8px;
  }


}

.results-vs-btns {
  display: flex;

  button {
    white-space: nowrap;
  }

  button:focus {
    background-color: #06a6fb !important;
    color: #ffffff !important;

  }

  button:nth-child(1) {
    margin-right: 10px;
  }

  button:nth-child(3) {
    margin-left: 10px;
  }
}

.device-selection {
  display: flex;

  button:focus {
    background-color: #06a6fb !important;
    color: #ffffff !important;

  }

  button {
    margin-right: 10px !important;
  }
}

#platform-selection {
  display: flex;

  button:focus {
    background-color: #06a6fb !important;
    color: #ffffff !important;

  }

  button {
    margin-right: 10px !important;
  }
}

.custom-date-range {
  padding-top: 15px;
}